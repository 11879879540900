.ql-picker.ql-font .ql-picker-item {
    font-size: 0;
}

.ql-picker.ql-font .ql-picker-item:before {
    content: attr(data-value) !important;
    font-size: 14px;
}

.ql-picker.ql-font .ql-active:before {
    content: attr(data-value) !important;
    font-size: 14px;
}

.ql-picker.ql-font .ql-picker-label[data-value="Ubuntu Mono"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Ubuntu Mono"]::before
{
    font-family: "Ubuntu Mono", monospace;
    content: "Ubuntu Mono" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before
{
    font-family: "Roboto", sans-serif;
    content: "Roboto" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before
{
    font-family: "Raleway", sans-serif;
    content: "Raleway" !important;
}

/* Set content font-families */
.ql-font-Roboto {
    font-family: "Roboto";
}
.ql-font-Raleway {
    font-family: "Raleway";
}
.ql-font-Montserrat {
    font-family: "Montserrat";
}

.ql-picker.ql-size .ql-picker-label {
    max-width: 70px !important;
    /*border: 1px solid red !important;*/
}

.ql-picker.ql-size .ql-picker-label[data-value="11px"]::before, .ql-picker.ql-size .ql-picker-item[data-value="11px"]::before {
    content: '11px';
    font-size: 11px !important;
}
.ql-picker.ql-size .ql-picker-label[data-value="12px"]::before, .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
    content: '12px';
    font-size: 12px !important;
}
.ql-picker.ql-size .ql-picker-label[data-value="13px"]::before, .ql-picker.ql-size .ql-picker-item[data-value="13px"]::before {
    content: '13px';
    font-size: 13px !important;
}
.ql-picker.ql-size .ql-picker-label[data-value="14px"]::before, .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
    content: '14px';
    font-size: 14px !important;
}
.ql-picker.ql-size .ql-picker-label[data-value="15px"]::before, .ql-picker.ql-size .ql-picker-item[data-value="15px"]::before {
    content: '15px';
    font-size: 15px !important;
}
.ql-picker.ql-size .ql-picker-label[data-value="16px"]::before, .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
    content: '16px';
    font-size: 16px !important;
}
.ql-picker.ql-size .ql-picker-label[data-value="17px"]::before, .ql-picker.ql-size .ql-picker-item[data-value="17px"]::before {
    content: '17px';
    font-size: 17px !important;
}
.ql-picker.ql-size .ql-picker-item[data-value="18px"]::before, .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before {
    content: '18px';
    font-size: 16px !important;
}
.ql-picker.ql-size .ql-picker-item[data-value="20px"]::before, .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before {
    content: '20px';
    font-size: 16px !important;
}
.ql-picker.ql-size .ql-picker-item[data-value="22px"]::before, .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before {
    content: '22px';
    font-size: 16px !important;
}
.ql-picker.ql-size .ql-picker-item[data-value="24px"]::before, .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before {
    content: '24px';
    font-size: 16px !important;
}
.ql-picker.ql-size .ql-picker-item[data-value="26px"]::before, .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before {
    content: '26px';
    font-size: 16px !important;
}

.ql-container.ql-snow {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #e2e8f0;
}

.ql-toolbar.ql-snow {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border: 1px solid #e2e8f0;
}

.ql-editor.ql-blank::before {
    font-style: normal;
    color: #b6c1cf;
    font-size: 16px;
}

.hide {
    display: none;
}

.output-html {
    font-size: 14px;
    margin: 8px 0;
}

.code {
    color: orange;
    background: rgba(0, 0, 0, 0.8);
    padding: 5px;
    border-radius: 5px;
}

html, body, #root {
  height: 100%;
}

#root {
  position: relative;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-datepicker .react-datepicker__time-container {
  width: 74px;
}
.react-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 74px;
}

span.fp-speed.fp-menu-container[aria-expanded="false"] .fp-menu {
  display: none;
}

span.fp-speed.fp-menu-container[aria-expanded="true"] .fp-menu {
  right: 3.3em;
}

span.fp-cc.fp-menu-container[aria-expanded="false"] .fp-menu {
  display: none;
}

.datePicker {
  padding: 7px 0 7px 15px;
  width: 100%;
  border-radius: 5px;
  color: #2183ED;
  font-weight: 500;
}

.download-report {
  width: 100%;
  display: block;
  padding: 5px;
  text-align: center;
  border: 1px solid #3EA2FD;
  background: #E3F2FE;
  color: #2183ED;
  font-weight: 500;
  margin: 7px auto;
  border-radius: 5px;
}

.download-report:hover,
.download-report:active,
.download-report:focus {
  background-color: #f0f0f0;
}

.chapter-image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.chapter-buttons {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.chapter-container:hover .chapter-image {
    opacity: 0.2;
}

.chapter-container:hover .chapter-buttons {
    opacity: 1;
}

.dropboxChooser div {
    width: 100%;
}

#pdf-controls {
    display: none;
}